<template>
  <div class="input-video">
    <div v-if="sourceVideoId" id="player"></div>
    <div v-else class="no-style">暂无视频</div>
  </div>
</template>
<script>
import { getVideoToken, getTrainVideoToken } from "@/api/businesscourse/video";

export default {
  props:{
    roles:{
      type: String,
      default: ''
    },
    heights:{
      type: Number,
      default: 0
    },
    sourceVideoIds:{
      type: String,
      default: ''
    }
  },
  data() {
    return {
      playerOptions: "",
      vidInfo: {},
      player: null,
      sourceVideoId: null,
      role: null,
      height: 0,
    };

  },
  created() {
    this.sourceVideoId = this.$route.query.sourceVideoId || this.sourceVideoIds;
    this.role = this.$route.query.role || this.roles;

    this.height = (document.body.clientHeight-this.heights) || 500;

  },
  mounted() {
    this.$nextTick(() => {
      this.showVideo();
    });
  },
  methods: {
    showVideo() {
      const params = {
        sourceVideoId: this.sourceVideoId,
      };
      if (this.role || this.role === "management") {
        getTrainVideoToken(params).then((res) => {
          if (res.state == "success") {
            this.vidInfo = JSON.parse(res.body.playToken);
            this.$nextTick(() => {
              this.loadPlayer();
            });
          }
        });
      } else {
        getVideoToken(params).then((res) => {
          if (res.state == "success") {
            this.vidInfo = JSON.parse(res.body.playToken);
            this.$nextTick(() => {
              this.loadPlayer();
            });
          }
        });
      }
    },

    loadPlayer() {
      this.destroyed();
      const polyvPlayer = window.polyvPlayer;
      this.player = polyvPlayer({
        wrap: "#player",
        // width: document.documentElement.clientWidth,
        // height: document.documentElement.clientHeight,
        width: "100%",
        height: this.height,
        vid: this.vidInfo.data.videoId,
        playsafe: this.vidInfo.data.token,
        code:'可达鸭编程—'+localStorage.getItem('userName')+'|'+localStorage.getItem('token_business')
      });
    },
    destroyed() {
      if (this.player) {
        this.player.destroy();
      }
      let obj = document.getElementById("player");
      if (obj) {
        obj.innerHTML = "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.input-video {
  overflow: hidden;
  background: #000;
}
.input-video::-webkit-scrollbar {
  display: none;
}
#player {
  // width: 800px;
  // margin: 0 auto;
}
.no-style {
  text-align: center;
  margin-top: 100px;
  font-size: 16px;
}
.button-m {
  margin-right: 10px;
}

.home {
  blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    font-size: 17.5px;
    border-left: 5px solid #eee;
  }

  hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
  }

  .col-item {
    margin-bottom: 20px;
  }

  ul {
    padding: 0;
    margin: 0;
  }

  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  color: #676a6c;
  overflow-x: hidden;

  ul {
    list-style-type: none;
  }

  h4 {
    margin-top: 0px;
  }

  h2 {
    margin-top: 10px;
    font-size: 26px;
    font-weight: 100;
  }

  p {
    margin-top: 10px;

    b {
      font-weight: 700;
    }
  }

  .update-log {
    ol {
      display: block;
      list-style-type: decimal;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0;
      margin-inline-end: 0;
      padding-inline-start: 40px;
    }
  }
}
</style>
